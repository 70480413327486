<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedInvoiceType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../inpayments.api.js";
export default {
    data() {
        return {
            selectedInvoiceType: "All",
            currentStatuses: [],
        };
    },

    components: {
        StatusButton: () => import("./StatusButton.vue"),
    },

    created() {
        if (!this.isGlobalClientSelected) this.getAll();
    },

    methods: {
        async getAll() {
            const { search, clientId, dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getCounts(search, clientId, dateFrom, dateTo);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedInvoiceType = query.status ? query.status : "uploaded";
        },

        statusClicked(status) {
            this.selectedInvoiceType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        capitalizeFirstLetter(string) {
            const translation = {
                inquired: "Förfrågan",
                draft: "Utkast",
                uploaded: "Uppladdad",
                beSupplemented: "Kompletteras",
                adminhandled: "Godkänd",
                all: "Alla",
            };
            return translation[string];
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
